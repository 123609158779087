
.container{
  background-color:#6A72C5;
  height: 100vh
  ;
 
}

#head-banner{
  width: 100%;
  
  background-image: url(./assets/headImg.png);
  background-size: cover;
  padding-top: 2%;
}

#head-banner div{
  margin-top: 5%;
  
}

#classplus-logo{
  width: 100%;
  margin-left: -2%;
}

#banner-texts{
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 4%;

}

#banner-subheading{
  font-family: circularLight;
  color: white;
  font-style : normal;
font-weight: bold;
font-size: 15px;
}

#howToWin-text{
  color: white;
  font-family: circularBook;
  font-style: normal;
  font-weight: 450;
  font-size: 15px;

  margin-bottom: 25%;
  padding-bottom: 5px;
  border-bottom: 1px dashed white;
}

#loading{

  font-size: 1.5rem;
  font-weight: 400;

}


.bottom-sheet{
  position: absolute;
  height: 73vh;
  width: 100%;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 99;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;

  -webkit-animation-name: slideIn;
  animation-name: slideIn;
  
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
 
}

.bottomsheet-data{

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

}

@keyframes fadein {
  0% {
    background-color:(0,0,0, 0);
  }
  100% {
    background-color:( 0,0,0, 0.8);
  }
}
@-webkit-keyframes fadein {
  0% {
    background-color:( 0,0,0, 0);
  }
  100% {
    background-color:( 0,0,0, 0.8);
  }
}


@-webkit-keyframes slideIn {
  0% {
    bottom: -100vh;
  }
  100% {
    bottom: 0;
  }
}

@keyframes slideIn {
  0% {
    bottom: -100vh;
  }
  100% {
    bottom: 0;
  }
}


@media screen and (min-width: 325px) {
  .bottom-sheet{
    height: 70vh;
  }


}

@media screen and (min-width: 375px) {
  .bottom-sheet{
    height: 72vh;
  }

}

@media screen and (min-width: 425px) {
  .bottom-sheet{
    height: 68vh;
  }

}

