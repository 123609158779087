.awardCard-container{
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3%;
}

#awardCard-heading-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 3%;
    gap: 3%;
  
}

#award-icon{
    width: 7%;
  
}

#awardCard-heading{
    width: 90%;
    font-family: circularBook;
font-style: normal;
font-weight: 500;
font-size: 18px;
}

.certificate{
    /* width: 90%;
    border-radius: 16px;
    margin-top: 5%; */
    width: 100%;
    border-radius: 16px;
}

#certificate-div{
    width: 90%;
}

#cta-container{
    width: 100%;
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 5%;
}

#download-cta, #watsapp-cta{
    width: 40%;
    
}