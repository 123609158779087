.award-card {
  width: 95%;
  height: 150px;
  padding: 0 0px 0 5px;
  border-bottom: 1px solid #e5e5e5;
}

.card-heading {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 3%;

    font-family: circularBook;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

#award-title {
    font-family: circularBook;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

#card-description {
  text-align: start;
    font-family: circularBook;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  padding-left: 5px;
  color: rgba(0, 0, 0, 0.6);
}

#card-awardIcon {
  width: 46px;
  height: 46px;
}

.award-description-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}