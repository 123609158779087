.empty-screen{
    display: flex;
    flex-direction: column;
    padding-left: 2%;
    padding-right: 2%;
    width: 90%
}

#empty-img{
    width: 40%;

}

#empty-text{
    font-size: 0.8rem;
    width: 50%;
    font-family: circularBook;
    text-align: center;
}

#awards-count{
    font-family: circularBook;
}   

.empty-data{
    display: flex;
    height: 60vh;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

#howtowinCTA{
    width: 40%;
    font-family: circularBook;
}