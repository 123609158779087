.Awards-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.awards-heading{
    background-color: white;
    position: sticky;
    /* border-bottom: 1px solid #E5E5E5; */
    top: 0;
    left: 0;
    padding: 4% 5% 4% 5%;
    width: 90%;
    font-family: circularBook;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
}