.howToWin-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    
}

.howToWin-heading{
   
    width: 100%;
    padding: 10px 0px 0px 0px;
    font-family: circularBook;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 10px;
    border-bottom: 1px solid #E5E5E5;
    background-color: white;
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 65%;

}

#howToWinP{
    padding-left: 5%;
    padding-right: 5%;
}

.award-description-container{
 
}

#howToWin-close{
    width: 6%;
}