@font-face {
  font-family: 'circularBlack';
  
  src: local('circular'), url("./assets/fonts/CircularStd-Black.otf") format('opentype');
}


@font-face {
  font-family: 'circularBold';
  src: local('circular'), url("./assets/fonts/CircularStd-Bold.otf") format('opentype');
}



@font-face {
  font-family: 'circularBook';
  src: local('circular'), url("./assets/fonts/CircularStd-Book.otf") format('opentype');
}


@font-face {
  font-family: 'circularLight';
  src: local('circular'), url("./assets/fonts/CircularStd-Light.otf") format('opentype');
}


@font-face {
  font-family: 'circularMedium';
  src: local('circular'), url("./assets/fonts/CircularStd-Medium.otf") format('opentype');
}





body {
  margin: 0;
  padding: 0;
}